import encoding from 'encoding-japanese'

export function utf8ToSjis(utf8_string) {
    /*
    let utf8_array = [];
    for (let i = 0; i < utf8_string.length; i++) {
        utf8_array.push(utf8_string.charCodeAt(i));
    }
    let sjis_array = encoding.convert(utf8_array, {
        to: 'SJIS',
        from: 'UNICODE',
    });
    let sjis_string = sjis_array.join("");
    */

    /*
    let sjis_string = encoding.convert(utf8_string, {
        to: 'SJIS',
        from: 'UTF8',
        type: 'string',
    });
    */

    let utf8_code = new encoding.stringToCode(utf8_string)
    let sjis_code = encoding.convert(utf8_code, 'SJIS');
    let sjis_string = new Uint8Array(sjis_code);

    return sjis_string
}
